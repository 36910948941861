/*  eslint-disable getter-return */
const supported = supportsPassive();
export default function passiveIfPossible() {
  let passive = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return supported ? {
    passive
  } : false;
}

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
function supportsPassive() {
  // Test via a getter in the options object to see if the passive property is accessed
  let supportPassiveCalled = false;
  try {
    const opts = Object.defineProperty({}, "passive", {
      get: function () {
        supportPassiveCalled = true;
      }
    });
    window.addEventListener("testpassive", null, opts);
  } catch (e) {
    return false;
  }
  return supportPassiveCalled;
}