import tracking from "./tracking";
const trackedArticle = [];
let scrollThresholds = 0;
function init(article, percentageScrolled) {
  if (!article) return;
  const articleTitle = article.dataset.title;
  if (trackedArticle.includes(articleTitle)) return;
  if (scrollThresholds === 100) scrollThresholds = 0;
  if (percentageScrolled === 100 && scrollThresholds < 100) {
    scrollThresholds = 100;
    trackPercentage(scrollThresholds);
    trackedArticle.push(articleTitle);
  } else if (percentageScrolled >= 75 && scrollThresholds < 75) {
    scrollThresholds = 75;
    trackPercentage(scrollThresholds);
  } else if (percentageScrolled >= 50 && scrollThresholds < 50) {
    scrollThresholds = 50;
    trackPercentage(scrollThresholds);
  } else if (percentageScrolled >= 25 && scrollThresholds < 25) {
    scrollThresholds = 25;
    trackPercentage(scrollThresholds);
  }
}
function trackPercentage(scrollThreshold) {
  tracking.trackCustomActive("engagement", "scroll", `${scrollThreshold}% scroll depth`);
}
export default {
  init
};