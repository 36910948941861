import { pushPodcastEvent } from "@exp/scripts/integration/gtm";
export { handleMileStoneTracking, handlePulseTracking, handleStartTracking };
const pulseIntervals = new Map();
function pushEvent(audio, gtmData, eventType, milestone) {
  pushPodcastEvent({
    ...gtmData
  }, {
    duration: audio.duration,
    muted: audio.volume <= 0,
    milestone,
    currentTime: audio.currentTime * 1000
  }, eventType);
}
function handleMileStoneTracking(audio, gtmData, milestone) {
  pushEvent(audio, gtmData, "milestone", milestone);
}
function handlePulseTracking(audio, gtmData, shouldStart) {
  if (shouldStart) {
    pulseIntervals.set(audio, setInterval(() => {
      pushEvent(audio, gtmData, "pulse");
    }, 15000));
  } else {
    clearInterval(pulseIntervals.get(audio));
    pulseIntervals.delete(audio);
  }
}
function handleStartTracking(audio, gtmData) {
  if (audio.currentTime < 1) {
    pushEvent(audio, gtmData, "start");
  }
}