import debounce from "../../helpers/debounce";
import tracking from "../tracking";
export default function init() {
  let scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  const element = scope.getElementsByClassName("search-widget")[0];
  if (!element) return;
  const form = element.getElementsByTagName("form")[0];
  const textField = element.getElementsByTagName("input")[0];
  const results = element.getElementsByClassName("search-widget__results")[0];
  const overlay = element.getElementsByClassName("search-widget__overlay")[0];
  form.addEventListener("reset", reset);
  textField.addEventListener("keyup", debounce(search, 200));
  results.addEventListener("click", trackResultLinkClick);
  overlay.addEventListener("click", reset);
  function search() {
    const searchString = textField.value.trim();
    if (searchString === "") return reset();
    if (/^[A-Öa-ö][A-Za-z0-9 -]*$/.test(searchString[0]) === false || searchString.length < 3) {
      return;
    }
    loading();
    fetch(`${window.Exp.config.path}/search-widget/?q=${encodeURI(searchString)}`).then(response => {
      if (!response.ok) throw 0;
      return response.text();
    }).then(done).catch(() => done("<p>Någonting gick fel! Försök igen senare.</p>"));
  }
  function reset() {
    textField.value = "";
    element.classList.remove("search-widget--loading", "search-widget--results");
  }
  function loading() {
    element.classList.add("search-widget--loading");
  }
  function done(items) {
    results.innerHTML = items;
    element.classList.add("search-widget--results");
    element.classList.remove("search-widget--loading");
  }
  function trackResultLinkClick(event) {
    const anchor = event.target.closest("a");
    if (!anchor) return;
    if (anchor.classList.contains("teaser-link")) {
      tracking.trackClick("searchMenu|search|dropdown", anchor.href);
    } else if (anchor.classList.contains("section__show-more")) {
      tracking.trackClick("searchMenu|search|dropdown|showmore", anchor.href);
    }
  }
}