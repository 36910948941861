import consentPrompt from "../plugins/consentPrompt";
export default function init(scope) {
  const widgetAreas = scope.getElementsByClassName("js-widget-area");
  if (!widgetAreas.length) return;
  [...widgetAreas].map(checkConsentStatusForVendors);
}
function checkConsentStatusForVendors(scope) {
  const tagsWithVendor = [...scope.getElementsByTagName("script")].filter(tag => {
    return tag && tag.dataset && tag.dataset.vendor;
  });
  if (!tagsWithVendor.length) return;
  const uniqueVendors = [];
  tagsWithVendor.forEach(vendorTag => {
    if (uniqueVendors.findIndex(tag => tag.dataset.vendor === vendorTag.dataset.vendor) === -1) {
      uniqueVendors.push(vendorTag);
    }
  });
  uniqueVendors.forEach(target => {
    const cmpVendorId = target && target.dataset.vendor;
    window.Exp = window.Exp || {};
    window.Exp.onConsentReady = window.Exp.onConsentReady || [];
    window.Exp.onConsentReady.push(Didomi => {
      if (!cmpVendorId || Didomi.getUserConsentStatusForVendor(cmpVendorId)) return;
      consentPrompt({
        target,
        vendorId: cmpVendorId
      });
    });
  });
}