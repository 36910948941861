const loadOffset = 200;
const useObserver = ("IntersectionObserver" in window);
export default function lazyLoadAds(container, infinityScroll) {
  if (!container) return;
  const adSlots = container.getElementsByClassName("bam-ad-slot");
  let observer;
  setupObserverForAdslots();
  function setupObserverForAdslots() {
    if (!useObserver) return;
    observer = setupObserver();
    for (let i = 0; i < adSlots.length; i++) {
      const adSlot = adSlots[i];
      observer.observe(adSlot);
    }
  }
  function setupObserver() {
    return new IntersectionObserver(viewPortUpdate, {
      rootMargin: `${loadOffset}px 0px`
    });
    function viewPortUpdate(entries) {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          observer.unobserve(entry.target);
          displayAd(entry.target);
        }
      });
    }
  }
  function displayAd(adSlot) {
    window.Bau.push(() => {
      window.Bau.displayAds(adSlot, getDisplayConfig());
    });
    function getDisplayConfig() {
      const displayConfig = {
        infinityScroll
      };
      if (!infinityScroll) return displayConfig;
      displayConfig.targeting = {
        tags: split(adSlot.dataset.targetingTags),
        ...(window.Exp.config.articleId && {
          articleId: window.Exp.config.articleId
        })
      };
      return displayConfig;
    }
    function split(str) {
      if (!str) return [];
      return str.split("#");
    }
  }
}