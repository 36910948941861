export default function init(scope) {
  if (!scope) return;
  const truncationElements = scope.getElementsByClassName("truncate");
  for (let i = 0; i < truncationElements.length; i++) {
    const truncated = truncationElements[i];
    const parent = truncated.parentElement;
    if (!parent) return;
    const truncateWrapper = parent.getElementsByClassName("truncate-wrapper")[0];
    if (!truncateWrapper) return;
    truncateWrapper.addEventListener("click", () => {
      truncated.classList.toggle("reset");
      truncated.classList.toggle("truncate");
      truncateWrapper.classList.add("hidden");
      const truncatedElements = parent.getElementsByClassName("truncated");
      [...truncatedElements].forEach(truncatedElement => {
        truncatedElement.classList.remove("truncated");
      });
    });
  }
}