function init(_ref) {
  let {
    container,
    trackSelector
  } = _ref;
  if (!container) return;
  const elements = container.getElementsByClassName(trackSelector);
  if (!elements) return;
  for (let i = 0; i < elements.length; i++) {
    const trackType = elements[i].dataset.tType;
    switch (trackType) {
      // click-track
      case "c-t":
        autoClickTrack(elements[i]);
        break;
      case "custom-active":
        autoCustomActive(elements[i]);
        break;
    }
  }
}
function autoClickTrack(element) {
  if (!element) return;
  const target = getRedirectTarget(element.href);
  const trackLabel = getTrackValue(element.dataset.tLabel);
  const clickUrl = element.dataset.clickUrl || "";
  const openInNewTab = element.target === "_blank";
  if (!trackLabel) return;
  element.addEventListener("click", e => {
    if (!openInNewTab) e.preventDefault();
    trackClick(trackLabel, clickUrl, () => {
      if (target && !openInNewTab) window.location = target;
    });
  });
}
function autoCustomActive(element) {
  if (!element) return;
  const target = getRedirectTarget(element.href);
  const trackLabel = getTrackValue(element.dataset.tLabel);
  const openInNewTab = element.dataset.target === "_blank";
  const trackCategory = element.dataset.tCategory || "action";
  const trackAction = element.dataset.tAction || "click";
  element.addEventListener("click", e => {
    if (!openInNewTab) e.preventDefault();
    trackCustomActive(trackCategory, trackAction, trackLabel, () => {
      if (!openInNewTab) {
        window.location = target;
      }
    });
  });
}
function track(trackingType, args, callback) {
  args = Array.isArray(args) ? args : [];
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  window.ExpressenAnalytics.push(analytics => {
    if (typeof analytics.track[trackingType] !== "function") {
      return console.error(`No such tracking type: ${trackingType}`); // eslint-disable-line
    }

    analytics.subscribeAfter("common", function eventHandler(eventName) {
      if (eventName === trackingType) {
        analytics.unsubscribeAfter("common", eventHandler);
      }
    });
    analytics.track[trackingType](...args);
    if (callback) return callback();
  });
}
function trackClick(clickIdentifier, url, callback) {
  track("click", [clickIdentifier, url], callback);
}
function trackCustomActive(eventCategory, eventAction, eventLabel, callback) {
  track("customActive", [eventCategory, eventAction, eventLabel], callback);
}
function trackCustomPassive(eventCategory, eventAction, eventLabel, callback) {
  track("customPassive", [eventCategory, eventAction, eventLabel], callback);
}
function getRedirectTarget(testUrl) {
  return testUrl && testUrl !== "javascript:void(0);" && testUrl !== "" ? testUrl : undefined; // eslint-disable-line
}

function getTrackValue(clickValue) {
  return clickValue && clickValue.length ? clickValue.toLowerCase().split(" ").join("_") : undefined;
}
export default {
  init,
  trackClick,
  trackCustomPassive,
  trackCustomActive,
  getTrackValue
};