import throttle from "../helpers/throttle";
const ads = {};
export default function init(Bau) {
  Bau.events.subscribe("resize", resizeAd);
  window.addEventListener("resize", throttle(resizeAds, 100));
}
function resizeAds() {
  Object.keys(ads).forEach(id => resizeAd(ads[id], true));
}
function resizeAd(adData) {
  if (!adData || !adData.name || adData.name.indexOf("rich_media") >= 0) return;
  const adId = adData.id;
  ads[adId] = adData;
  if (adData.isEmpty) return;
  const element = document.getElementById(adId);
  if (!element) return;
  const inner = element.firstElementChild;
  if (!inner) return;
  const {
    width,
    height
  } = adData.size;
  const maxWidth = element.offsetWidth;
  if (width <= maxWidth) {
    inner.style.removeProperty("transform");
    element.style.removeProperty("height");
    element.style.removeProperty("minHeight");
    return;
  }
  const scale = maxWidth / width;
  inner.style.transformOrigin = "left top";
  inner.style.transform = `scale(${scale})`;
  element.style.height = `${scale * height + 60}px`; // 30px *2 ::before/::after
  element.style.minHeight = "auto";
}