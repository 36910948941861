import videoPlayer from "@exp/scripts/plugins/video-player";
export default function init() {
  let scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  let lazy = arguments.length > 1 ? arguments[1] : undefined;
  videoPlayer(scope, lazy, getStickyOptions, {
    stickyVideo: true
  });
}
function getStickyOptions() {
  const stickyOptions = {};
  if (window.CHANNEL_MOBILE) {
    stickyOptions.stickyStyle = {
      left: "16px",
      right: "16px",
      "z-index": "20"
    };
  } else {
    stickyOptions.stickyStyle = {
      right: "50px",
      bottom: "70px",
      width: "320px",
      "z-index": "20"
    };
  }
  return stickyOptions;
}