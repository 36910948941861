import tracking from "./tracking";
import { getCookie } from "@exp/scripts/helpers/cookies";
import { checkConsentSync } from "../integration/didomi";
export default function init(scope) {
  const showCommentsButton = scope.getElementsByClassName("js-load-comments")[0];
  if (!showCommentsButton) return;
  const {
    articleIdentifier,
    articleUrl
  } = showCommentsButton.dataset;
  showCommentsButton.addEventListener("click", () => {
    tracking.trackClick("show_comments");
    const activeComments = document.getElementsByClassName("article__comments--active")[0];
    if (activeComments) {
      const activeCommentsThread = activeComments.getElementsByClassName("js-comments-thread")[0];
      if (activeCommentsThread) {
        activeCommentsThread.remove();
      }
      activeComments.classList.remove("article__comments--active");
    }
    showCommentsButton.parentElement.classList.add("article__comments--active");
    const commentsThread = document.createElement("div");
    commentsThread.setAttribute("id", "coral_thread");
    commentsThread.setAttribute("class", "js-comments-thread");
    scope.getElementsByClassName("js-comments-wrapper")[0].appendChild(commentsThread);
    loadComments(articleIdentifier, articleUrl);
  });
}
async function loadComments(articleIdentifier, articleUrl) {
  const brandName = window.Exp.config.brandName;
  const bodyClassName = getColorSchemeClass();
  const endpoint = "/comments"; // only work on subdomains
  let coralCookie;
  await window.fetch(endpoint).then(response => response.json()).then(data => {
    if (data.status === "Created" || data.status === "Active") {
      coralCookie = getCookie(`${brandName}_coralToken`);
    }
  }).catch(error => console.log("Fetching error: ", error)); // eslint-disable-line no-console

  const coralOptions = {
    id: "coral_thread",
    bodyClassName,
    autoRender: true,
    rootURL: window.Exp.config.coralUrl,
    storyID: articleIdentifier,
    storyURL: articleUrl,
    events: function (events) {
      events.on("loginPrompt", () => {
        // Redirect user to a login page.
        const loginUrl = new URL("/login", window.Exp.config.loginBaseUrl);
        loginUrl.searchParams.set("returnUri", `${window.location.href}#no_universal_links`);
        location.href = loginUrl.href;
      });
    },
    accessToken: coralCookie
  };
  if (window.Coral) {
    window.Coral.createStreamEmbed(coralOptions);
  } else {
    const d = document,
      s = d.createElement("script");
    s.src = `${window.Exp.config.coralUrl}/assets/js/embed.js`;
    s.async = false;
    s.defer = true;
    s.onload = function () {
      window.Coral.createStreamEmbed(coralOptions);
    };
    (d.head || d.body).appendChild(s);
  }
}
function getColorSchemeClass() {
  if (checkConsentSync("functional")) {
    const colorScheme = getCookie("colorScheme");
    if (colorScheme === "dark") {
      return "comment-wrapper--dark";
    }
  }
  return "comment-wrapper";
}