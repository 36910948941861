import tracking from "./tracking";
export default function init(scope) {
  if (!scope) return;
  const furtherReadingTeasersColumn = scope.getElementsByClassName("further-reading-teasers")[0];
  if (!furtherReadingTeasersColumn) return;
  const anchors = furtherReadingTeasersColumn.getElementsByTagName("a");
  [...anchors].forEach(anchor => {
    anchor.addEventListener("click", () => {
      // filter out teaser section link
      if (anchor.parentElement.classList.contains("teaser__bottom")) return;
      tracking.trackClick(`wideningComponentItem|${window.Exp.config.brandName}`, anchor.href);
    });
  });
}