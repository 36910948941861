export const fetchArticle = contentId => {
  const contentPath = `${window.Exp.config.path}/partials${contentId}/`;
  return fetch(contentPath, {
    credentials: "same-origin",
    headers: {}
  }).then(response => {
    if (response.status !== 200) return null;
    return response.text();
  });
};
export const getNativeArticle = () => {
  let nativePath = `${window.Exp.config.nativeEngine}${window.location.pathname}`;
  if (window.Exp.config.hasTopDomain) {
    nativePath = `${nativePath}?site=${window.Exp.config.brandName}`;
  }
  return fetch(nativePath).then(response => response.status === 200 && response.json());
};
export const getScrollRecommendations = () => {
  const pathBrandName = window.Exp.config.path;
  const pathName = window.location.pathname;
  return fetch(`${pathBrandName}/recommendation/?path=${pathName}`).then(response => response.status === 200 && response.json());
};