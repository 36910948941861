import passiveIfPossible from "@exp/scripts/helpers/passive-if-possible";
export default function slider(container, params) {
  if (!container) return;
  const itemContainer = container.getElementsByClassName(params.itemContainer)[0];
  const items = itemContainer && itemContainer.children;
  if (items && items.length < 2) return;
  let currentIndex = 0;
  const itemCount = items.length;
  const {
    autoplay,
    containsVideo
  } = params;
  let measurementsTaken = false;
  let autoplayInterval;
  let autoplayPaused;
  let itemContainerWidth = itemContainer.getBoundingClientRect().width;
  let lastItemOffset;
  const callbacks = {};
  let firstSlideTracked = false;
  let userHasInteracted = false;
  const {
    slideshowIndex,
    enablePageViewTracking,
    alignLastItemToRight,
    trackingLabel
  } = params;
  const defaultDuration = 300;
  const _slider = {
    slideToNext,
    slideToPrevious,
    slideToIndex,
    isLastIndex,
    isFirstIndex,
    getCurrentIndex,
    onUpdate: addCallback.bind(null, "update"),
    onFirstInteraction: addCallback.bind(null, "firstInteraction")
  };
  init();
  return _slider;
  function init() {
    initSwipeControls();
    initButtonControls();
    startAutoplay();
    itemContainer.style.transitionDuration = `${defaultDuration}ms`;
    if (window.CHANNEL_RESPONSIVE) {
      window.addEventListener("layoutchange", invalidateMeasurements);
    }
  }
  function getCurrentIndex() {
    return currentIndex;
  }
  function slideToNext(userTriggered) {
    slideToIndex(currentIndex + 1, userTriggered);
  }
  function slideToPrevious(userTriggered) {
    slideToIndex(currentIndex - 1, userTriggered);
  }
  function slideToIndex(index) {
    let userTriggered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    let duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultDuration;
    if (userTriggered) runOnFirstInteraction();
    itemContainer.style.transitionDuration = `${duration}ms`;
    setCurrentItem(index);
    if (userTriggered) {
      if (trackingLabel) {
        window.ExpressenAnalytics = window.ExpressenAnalytics || [];
        window.ExpressenAnalytics.push(analytics => {
          analytics.track.customActive(trackingLabel, "slide", clampIndex(index));
        });
      }
      trackAdditionalPageView();
      pauseAutoplay();
    }
  }
  function trackAdditionalPageView() {
    if (firstSlideTracked || !enablePageViewTracking) return;
    window.ExpressenAnalytics = window.ExpressenAnalytics || [];
    window.ExpressenAnalytics.push(api => {
      api.track.slideshowFirstSlide(slideshowIndex || 1);
      firstSlideTracked = true;
    });
  }
  function setCurrentItem(index) {
    const targetIndex = clampIndex(index);
    const targetPosition = getPosition(targetIndex);
    itemContainer.style.transform = `translateX(${targetPosition}%)`;
    if (targetPosition === 0) {
      itemContainer.style.transform = "none";
    }
    const previousIndex = currentIndex;
    currentIndex = targetIndex;
    if (callbacks.update) {
      callbacks.update.forEach(fn => fn(currentIndex, previousIndex));
    }
  }
  function getPosition(index) {
    const position = -(index * 100);
    if (alignLastItemToRight && isLastIndex(index)) {
      takeMeasurements();
      return Math.round((position + lastItemOffset) * 1000) / 1000;
    }
    return position;
  }
  function isLastIndex(index) {
    return index === itemCount - 1;
  }
  function isFirstIndex() {
    return currentIndex === 0;
  }
  function clampIndex(index) {
    if (index >= itemCount) return 0;
    if (index < 0) return itemCount - 1;
    return index;
  }
  function invalidateMeasurements() {
    measurementsTaken = false;
  }
  function takeMeasurements() {
    if (measurementsTaken) return;
    measurementsTaken = true;
    itemContainerWidth = itemContainer.getBoundingClientRect().width;
    lastItemOffset = getLastItemOffset();
    function getLastItemOffset() {
      const containerWidth = container.getBoundingClientRect().width;
      if (containerWidth <= itemContainerWidth) return 0;
      const marginWidth = parseInt(window.getComputedStyle(items[items.length - 1]).getPropertyValue("border-right-width"));
      return (containerWidth - (itemContainerWidth - marginWidth)) / itemContainerWidth * 100;
    }
  }
  function startAutoplay() {
    if (!autoplay) return;
    autoplayInterval = setInterval(() => slideToNext(false), 6000);
  }
  function pauseAutoplay() {
    let autoresume = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    if (!autoplay) return;
    clearInterval(autoplayInterval);
    clearTimeout(autoplayPaused);
    if (autoresume) {
      autoplayPaused = setTimeout(startAutoplay, 3000);
    }
  }
  function addCallback(event, fn) {
    if (callbacks[event]) {
      return callbacks[event].push(fn);
    }
    callbacks[event] = [fn];
  }
  function runOnFirstInteraction() {
    if (userHasInteracted) return;
    userHasInteracted = true;
    if (callbacks.firstInteraction) {
      callbacks.firstInteraction.forEach(fn => fn());
    }
  }
  function initSwipeControls() {
    let start;
    let delta;
    const notPassive = passiveIfPossible(false);
    itemContainer.addEventListener("touchstart", onStart, notPassive);
    videoSwipeAreas(itemContainer);
    function onStart(event) {
      if (slideItemIsSticky()) return;
      const touchEvent = event.touches ? event.touches[0] : event;
      start = {
        x: touchEvent.pageX,
        y: touchEvent.pageY,
        position: getPosition(currentIndex),
        time: Date.now()
      };
      window.addEventListener("touchmove", onFirstMove, notPassive);
    }
    function onFirstMove(event) {
      window.removeEventListener("touchmove", onFirstMove, notPassive);
      // ensure swiping with one touch and not pinching
      if (event.touches.length > 1 || event.scale && event.scale !== 1) return;
      const touchEvent = event.touches[0];
      const isScrolling = Math.abs(touchEvent.pageX - start.x) < Math.abs(touchEvent.pageY - start.y);
      if (isScrolling) return;
      event.preventDefault();
      window.addEventListener("touchmove", onMove, notPassive);
      window.addEventListener("touchend", onEnd);
      itemContainer.style.transitionDuration = "0s";
      runOnFirstInteraction();
    }
    function onMove(event) {
      event.preventDefault(); // prevent native scrolling

      // measure change in x and y
      const touchEvent = event.touches[0];
      delta = touchEvent.pageX - start.x;

      // we add an edge slide resistance to swipe
      if (isPastBounds()) {
        delta = delta / (Math.abs(delta) / itemContainerWidth + 1);
      }
      pauseAutoplay(false);
      const targetPosition = start.position + delta / itemContainerWidth * 100;
      itemContainer.style.transform = `translateX(${targetPosition}%)`;
    }
    function onEnd() {
      window.removeEventListener("touchmove", onMove, notPassive);
      window.removeEventListener("touchend", onEnd);
      const direction = !isSwipe() || isPastBounds() ? 0 : sign(delta);
      const duration = Math.min(Date.now() - start.time, defaultDuration);
      slideToIndex(currentIndex - direction, direction !== 0, duration);
      if (direction === 0) pauseAutoplay();
      start = undefined;
      delta = undefined;
      function sign(x) {
        return (x > 0) - (x < 0) || +x;
      }
    }
    function isSwipe() {
      if (!delta) return false;
      const duration = Date.now() - start.time;
      const isQuickSwipe = Number(duration) < 250 && Math.abs(delta) > 40;
      const isLongSwipe = Math.abs(delta) > itemContainerWidth / 2;
      return isQuickSwipe || isLongSwipe;
    }
    function isPastBounds() {
      if (delta > 0 && currentIndex === 0) return true;
      if (delta < 0 && currentIndex === itemCount - 1) return true;
      return false;
    }
  }
  function initButtonControls() {
    const previousButton = container.getElementsByClassName(`${params.controls}--previous`)[0];
    const nextButton = container.getElementsByClassName(`${params.controls}--next`)[0];
    if (previousButton) {
      previousButton.addEventListener("click", slideToPrevious);
    }
    if (nextButton) {
      nextButton.addEventListener("click", slideToNext);
    }
  }
  function slideItemIsSticky() {
    if (!containsVideo) return false;
    const videoIsSticky = !!itemContainer.classList.contains("slider__items--has-stuck-video");
    return videoIsSticky;
  }
}
function videoSwipeAreas(scope) {
  if (!scope) return;
  const areas = scope.getElementsByClassName("slider__video-swipe-area");
  for (let i = 0; i < areas.length; ++i) {
    setup(areas[i]);
  }
  function setup(area) {
    const clickTargetParent = area.previousElementSibling;
    area.addEventListener("click", passClickToTarget);
    function passClickToTarget(event) {
      const {
        offsetX,
        offsetY
      } = event;
      let clickTarget;
      if (clickTargetParent.tagName === "IFRAME") {
        const iframeDocument = clickTargetParent.contentWindow.document;
        clickTarget = iframeDocument.elementFromPoint(offsetX, offsetY);
      } else {
        clickTarget = clickTargetParent;
      }
      if (!clickTarget) return;
      clickTarget.click();
    }
  }
}