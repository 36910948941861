const useObserver = ("IntersectionObserver" in window);
export default function init(lazyConfig) {
  let {
    selectorClass,
    scope
  } = lazyConfig;
  scope = scope || document;
  const widgets = scope.getElementsByClassName(selectorClass);
  if (!widgets && !widgets.length) return;
  const loadOffset = window.innerHeight;
  let observer;
  setupIntersectionObserver();
  function setupIntersectionObserver() {
    if (!useObserver) return;
    observer = setupObserver();
    for (let i = 0; i < widgets.length; i++) {
      const widget = widgets[i];
      observer.observe(widget);
    }
  }
  function setupObserver() {
    return new IntersectionObserver(viewPortUpdate, {
      rootMargin: `${loadOffset}px 0px`
    });
    function viewPortUpdate(entries) {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          observer.unobserve(entry.target);
          loadWidget(entry.target);
        }
      });
    }
  }
  function loadWidget(widget) {
    if (!widget) return;
    const noscript = widget.getElementsByTagName("noscript")[0];
    if (!noscript) return;
    const script = document.createElement("script");
    script.insertAdjacentHTML("beforeend", noscript.textContent);
    widget.appendChild(script);
    noscript.parentElement.removeChild(noscript);
  }
}