import { getCookie, removeCookie } from "@exp/scripts/helpers/cookies";
import { checkConsentSync, checkConsent } from "../integration/didomi";
export default function init(scope) {
  const containers = scope.getElementsByClassName("js-recipe-rating");
  if (!containers) return;
  [...containers].forEach(container => {
    const votingId = container.dataset.votingId;
    checkConsent("functional", "c:bonniernews", consentGiven => {
      if (!consentGiven) return;
      const cookieName = `recipeRating-${votingId}`;
      const alreadyVoted = getCookie(cookieName);
      if (alreadyVoted || !isNaN(alreadyVoted)) {
        const {
          mainRating
        } = container.dataset;
        // we check this because some ratings have been reset
        if (parseInt(mainRating) <= 0) {
          removeCookie(cookieName);
        } else {
          const ratingStatus = container.getElementsByClassName("rating__status")[0];
          const ratingStars = container.getElementsByClassName("rating__stars")[0];
          ratingStars.classList.add("disabled");
          ratingStatus.innerHTML = `Ditt betyg: ${alreadyVoted}/5`;
        }
      }
    });
    container.addEventListener("click", e => {
      const button = e.target.closest("button");
      if (!button) return;
      if (checkConsentSync("functional")) {
        vote(button, votingId);
      } else {
        const ratingStatus = container.getElementsByClassName("rating__status")[0];
        ratingStatus.innerHTML = "Du behöver ändra dina kakinställningar för att sätta betyg. <a onClick=window.Didomi.notice.show()>Hantera kakor</a>";
      }
    });
  });
  function vote(element, votingId) {
    const {
      rating
    } = element.dataset;
    const alreadyVoted = getCookie(`recipeRating-${votingId}`);
    if (alreadyVoted) return;
    postRating(votingId, parseInt(rating));
  }
  function postRating(recipeId, rating) {
    const contentPath = `${window.Exp.config.path}/rating/recipe/${recipeId}/`;
    window.fetch(contentPath, {
      method: "POST",
      crossOrigin: true,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        rating
      })
    }).then(response => {
      if (response.status !== 200) return null;
      return response.text();
    }).then(markup => {
      if (!markup || markup.length <= 1) return null;
      [...containers].forEach(container => {
        const dynamicContainer = container.getElementsByClassName("rating__dynamic")[0];
        const ratingStatus = container.getElementsByClassName("rating__status")[0];
        dynamicContainer.innerHTML = markup;
        const ratingStars = dynamicContainer.getElementsByClassName("rating__stars")[0];
        ratingStatus.innerText = `Tack för ditt betyg (${rating}/5)`;
        ratingStars.classList.add("disabled");
      });
    });
  }
}