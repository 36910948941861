window.ExpressenAnalytics = window.ExpressenAnalytics || [];
export default function accountMenu() {
  const element = document.getElementsByClassName("account-menu")[0];
  const header = element && document.getElementsByClassName("site-header")[0];
  const toggle = header && header.getElementsByClassName("account-menu__toggle")[0];
  if (!element || !toggle) return;
  element.addEventListener("click", event => event.stopPropagation());
  element.addEventListener("focusout", focusToggle);
  toggle.addEventListener("click", toggleMenu);
  const user = getUser();
  if (user) {
    const userInfo = element.getElementsByClassName("account-menu__user-info")[0];
    userInfo.children[0].textContent = user.name;
  }
  function toggleMenu(event) {
    event.stopPropagation();
    event.preventDefault();
    const isHidden = element.classList.toggle("hidden");
    if (isHidden) {
      document.body.removeEventListener("click", toggleMenu);
    } else {
      document.body.addEventListener("click", toggleMenu);
      element.focus();
    }
    if (!isHidden) {
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.customActive("navigation", "click", "accountMenu|openMenu");
      });
    }
  }
  function focusToggle(event) {
    if (!element.contains(event.relatedTarget)) {
      toggle.focus();
    }
  }
}
function getUser() {
  try {
    const user = window.Exp.user;
    return {
      name: user.givenName
    };
  } catch (error) {
    return null;
  }
}