import audioPlayer, { formatTime } from "@exp/components/audio-player/audio-player.mjs";
export default async function init() {
  let scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  const audioPlayerWidgets = Array.from(scope.getElementsByClassName("audio-player"));
  for (let i = 0; i < audioPlayerWidgets.length; i++) {
    const element = audioPlayerWidgets[i];
    const content = await getAPIContent(element);
    if (!content) {
      triggerErrorState(element);
    } else {
      const updatedPlayer = updateAudioData(element, content);
      audioPlayer(updatedPlayer);
    }
  }
}
async function getAPIContent(element) {
  const url = element.querySelector("audio")?.dataset?.episodeUrl;
  const episode = new URL(url).searchParams.get("episode");
  const apiUrl = `https://sme.expressen.se/cors-proxy/${encodeURIComponent(`https://pod.space/api/embed?episode=${episode}`)}`;
  try {
    const response = await fetch(apiUrl);
    if (response?.ok) {
      const data = await response.json();
      return data?.error ? null : data;
    }
  } catch (error) {
    return null;
  }
}
function triggerErrorState(element) {
  element.classList.add("audio-player--error");
}
function updateAudioData(player, data) {
  const updatedPlayer = player;
  updatedPlayer.dataset.tracking = JSON.stringify(data);
  const audio = updatedPlayer.querySelector("audio");
  const image = updatedPlayer.querySelector("img");
  const title = updatedPlayer.querySelector(".audio-player__information__title");
  const name = updatedPlayer.querySelector(".audio-player__information__name");
  const totalTime = updatedPlayer.querySelector(".audio-player__duration__total-time");
  if (!audio || !image || !title || !name || !totalTime) return;
  audio.src = data.media_url;
  image.src = data.artwork;
  title.textContent = data.title;
  name.textContent = data.show.title;
  totalTime.textContent = formatTime(data.duration);
  return updatedPlayer;
}