const defaultOptions = {
  path: "/",
  domain: getDefaultDomain()
};
let cookies, loadedCookies;
loadCookies();
function getCookie(cookieName) {
  if (loadedCookies !== document.cookie) {
    loadCookies();
  }
  return cookies[cookieName];
}
function setCookie(cname, cvalue) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  document.cookie = `${cname}=${cvalue}; ${getOptions(options)}`;
  loadCookies();
}
function loadCookies() {
  const cookiesSplit = document.cookie.split(";");
  loadedCookies = document.cookie;
  cookies = cookiesSplit.reduce((result, cookie) => {
    const nameIdx = cookie.indexOf("=");
    const name = cookie.slice(0, nameIdx);
    const value = cookie.slice(nameIdx + 1);
    result[name.trim()] = value;
    return result;
  }, {});
}
function removeCookie(name) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  options.expires = "Thu, 01 Jan 1970 00:00:01 GMT";
  const emptyCookie = `${name}=; ${getOptions(options)}`;
  document.cookie = emptyCookie;
  delete cookies[name];
}
function getOptions(overrides) {
  const uniqueOptions = Object.assign({}, defaultOptions, overrides);
  const options = Object.keys(uniqueOptions).map(key => `${key}=${uniqueOptions[key]}`);
  return options.join("; ");
}
function getDefaultDomain() {
  const topDomain = ".expressen.se";
  const hostname = window.location.hostname || topDomain;
  if (hostname.indexOf(topDomain) === -1) return hostname;
  return topDomain;
}
export { getCookie, setCookie, removeCookie };