export default function init(scope) {
  const tableWrapper = scope.getElementsByClassName("tvTable");
  if (tableWrapper.length > 1) {
    [...tableWrapper].forEach(title => {
      const titleRow = title.getElementsByTagName("h4")[0];
      if (!titleRow) return;
      titleRow.parentElement.classList.add("table-heading");
    });
  }
  if (tableWrapper.length === 1) {
    [...tableWrapper].forEach(table => {
      const tableTitles = table.getElementsByTagName("h4");
      const countColspans = document.getElementsByClassName("tvTable")[0].getElementsByTagName("tr")[1];
      [...tableTitles].forEach(title => {
        title.parentElement.classList.add("table-heading");
        title.parentElement.setAttribute("colspan", countColspans.childElementCount);
        [...title.childNodes].forEach(child => {
          child.style.color = "#ffffff";
        });
      });
    });
  }
}