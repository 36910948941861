import getAppChannel from "../helpers/get-app-channel";
import { hasCMP } from "../integration/didomi";
let resourcesAreLoaded = false;
export default function init() {
  let scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  let lazy = arguments.length > 1 ? arguments[1] : undefined;
  let getStickyOptions = arguments.length > 2 ? arguments[2] : undefined;
  let extraOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  ensureResourcesAreLoaded(scope);
  window.playerManager = window.playerManager || [];
  if (lazy) {
    window.playerManager.push(manager => {
      manager.update();
    });
  } else {
    window.playerManager.push(manager => {
      const options = {
        ...extraOptions,
        autoplayNext: true,
        bugsnag: "none",
        className: "evp",
        stickyOptions: getStickyOptions(),
        cmpEnabled: hasCMP(),
        zIndex: 10,
        canClosePip: true,
        useCdn: true,
        onBeforePlayerInit(player) {
          player.theo.element.addEventListener("stickyopen", () => {
            document.body.classList.add("video-stuck");
          });
          player.theo.element.addEventListener("stickyclose", () => {
            document.body.classList.remove("video-stuck");
          });
          player.theo.element.addEventListener("stickyclosebutton", () => {
            document.body.classList.remove("video-stuck");
          });
        }
      };
      if (window.Exp.pageTemplateName === "storytelling-article") {
        options.autoplayNext = false;
      }
      if (window.Exp && window.Exp.experiments && window.Exp.experiments.clickToPlayVideo) {
        if (window.Exp.pageTemplateName === "webtv-article") {
          options.autoplay = false;
        }
      } else {
        if (window.CHANNEL_TABLET || window.CHANNEL_DESKTOP) {
          options.autoplayWithSound = window.Exp && window.Exp.pageTemplateName === "webtv-article";
        }
      }
      const appChannel = getAppChannel();
      if (appChannel) {
        options.channel = appChannel;
      }
      manager.init(options);
    });
  }
}
function ensureResourcesAreLoaded(scope) {
  if (resourcesAreLoaded) return;
  if (!scope.getElementsByClassName("video-player").length) return;
  resourcesAreLoaded = true;
  const resources = document.getElementsByClassName("video-player-resource");
  for (let i = 0; i < resources.length; ++i) {
    const resource = resources[i];
    if (resource.dataset.src) {
      resource.src = resource.dataset.src;
    } else if (resource.dataset.href) {
      resource.href = resource.dataset.href;
    }
  }
}