export default function expandTagsInArticle(scope) {
  if (!scope) return;
  const toggleButtons = scope.getElementsByClassName("js-toggle-tags");
  [...toggleButtons].forEach(toggleButton => {
    toggleButton.addEventListener("click", toggleTags);
  });
  function toggleTags(e) {
    e.preventDefault();
    e.currentTarget.classList.add("hide");
    e.currentTarget.parentElement.classList.add("show");
  }
}