export default function setup(article) {
  if (!article) return;
  const panels = article.getElementsByClassName("social-panel");
  for (let i = 0; i < panels.length; i++) {
    if (window.navigator.share) {
      shareButton(panels[i]);
    } else {
      serviceLinksSet(panels[i]);
    }
    copyToClipboardButton(panels[i]);
  }
}
function shareButton(panel) {
  const button = panel && panel.getElementsByClassName("social-panel__share")[0];
  if (!button) return;
  const documentMeta = panel.dataset;
  button.addEventListener("click", () => {
    window.navigator.share({
      title: documentMeta.title,
      url: documentMeta.url
    }).then(() => {
      window.ExpressenAnalytics = window.ExpressenAnalytics || [];
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.socialShare("web-share");
      });
    });
  });
}
function copyToClipboardButton(panel) {
  const button = panel && panel.getElementsByClassName("social-panel__copy-address")[0];
  if (!button) return;
  const copyState = button.children[0];
  const copiedState = button.children[1];
  button.addEventListener("click", () => {
    if (!window.navigator.clipboard) return;
    window.navigator.clipboard.writeText(panel.dataset.url).then(() => {
      copyState.classList.add("hidden");
      copiedState.classList.remove("hidden");
      window.ExpressenAnalytics = window.ExpressenAnalytics || [];
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.customActive("social panel click", "copy to clipboard");
      });
    }).catch(() => {});
  });
}
function serviceLinksSet(panel) {
  const button = panel && panel.getElementsByClassName("social-panel__share")[0];
  const linkContainer = panel && panel.getElementsByClassName("social-panel__service-links")[0];
  if (!button || !linkContainer) return;
  button.addEventListener("click", toggleContainer);
  const links = linkContainer.getElementsByTagName("a");
  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener("click", trackSocialShare);
  }
  function toggleContainer(e) {
    const isHidden = linkContainer.classList.toggle("hidden");
    if (isHidden) {
      document.body.removeEventListener("click", toggleContainer);
    } else {
      e.stopPropagation();
      document.body.addEventListener("click", toggleContainer);
    }
  }
  function trackSocialShare() {
    window.ExpressenAnalytics = window.ExpressenAnalytics || [];
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.socialShare(this.dataset.target);
    });
  }
}