import { pushArticleLinkClick } from "@exp/scripts/integration/gtm";
export default function trackBrandedLinkClick(container) {
  const brandedLinks = container.querySelectorAll('a[rel*="sponsored"]');
  if (brandedLinks.length === 0) return;
  Array.from(brandedLinks).forEach(link => {
    if (link.href) {
      link.addEventListener("click", () => {
        pushArticleLinkClick(link);
      });
    }
  });
}