import { checkConsent, checkConsentForVendorSync } from "../integration/didomi";
import { getCookie, setCookie } from "../helpers/cookies";
export default function init() {
  checkConsent("marketing", "c:bonniernews", consentGiven => {
    if (!consentGiven || getCookie("fbp") || !checkConsentForVendorSync("c:bn-facebookpixeln")) return;
    const segments = getCookie("bau_deliverance");
    if (segments) {
      createIframe(segments);
      setCookie("fbp", true);
    }
  });
}
function createIframe(segments) {
  const iframeEl = document.createElement("iframe");
  iframeEl.style.display = "none";
  iframeEl.sandbox = "allow-scripts allow-same-origin";
  iframeEl.src = "https://expressen.se/documents/facebook-pixel-proxy.html";
  iframeEl.onload = () => {
    iframeEl.contentWindow.postMessage(segments, "*");
  };
  document.body.appendChild(iframeEl);
}