const visibleMax = 5;
const min = 0;
export default function bullets(container, _slider, index) {
  const state = {
    truncatePaging: container.classList.contains("slider--truncate-paging"),
    bullets: container.querySelectorAll(".slider__bullets b"),
    max: container.querySelectorAll(".slider__bullets b").length - 1,
    current: 0,
    cutoffEnd: 0,
    cutoffStart: 0
  };
  init(state, _slider);
  slide(state, _slider, index);
}
function init(state, _slider) {
  if (_slider) {
    _slider.onUpdate(currentIndex => {
      slide(state, _slider, currentIndex);
    });
  }
  for (let i = 0; i < state.bullets.length; ++i) {
    const bullet = state.bullets[i];
    bullet.addEventListener("click", () => {
      if (!bullet.classList.contains("slider__bullet--current")) {
        _slider.slideToIndex(i);
      }
    });
  }
}
function slide(state, _slider, index) {
  const [oldCurrent, oldCutoffEnd, oldCutoffStart] = [state.current, state.cutoffEnd, state.cutoffStart];
  state.current = Math.max(min, Math.min(index, state.max));
  const cutoffSide = Math.floor(visibleMax / 2);
  // cutoff edge at current - cutoffSide, but don't go beyond first item or further than last - visible items
  state.cutoffEnd = Math.min(Math.max(min, state.current - cutoffSide), state.max - visibleMax + 1);
  state.cutoffStart = Math.min(state.max, state.cutoffEnd + visibleMax - 1);
  if (state.current !== oldCurrent) {
    state.bullets[oldCurrent].classList.remove("slider__bullet--current");
    if (state.truncatePaging) {
      state.bullets[oldCutoffEnd].classList.remove("slider__bullet--cutoff-end");
      state.bullets[oldCutoffStart].classList.remove("slider__bullet--cutoff-start");
    }
    state.bullets[state.current].classList.add("slider__bullet--current");
    if (state.truncatePaging) {
      state.bullets[state.cutoffEnd].classList.add("slider__bullet--cutoff-end");
      state.bullets[state.cutoffStart].classList.add("slider__bullet--cutoff-start");
    }
  }
}