import sectionObserver from "./sectionObserver";
export default function init(scope) {
  scope = scope || document;
  const sectionContainer = scope.getElementsByClassName("section")[0];
  const loadMoreBtn = scope.getElementsByClassName("js-section-show-more")[0];
  if (!sectionContainer || !loadMoreBtn) return;
  const {
    type
  } = loadMoreBtn.dataset;
  let page = 3;
  loadMoreBtn.addEventListener("click", loadMoreContent);
  function loadMoreContent() {
    let path = window.Exp.config.path;
    if (type === "tag-topic") {
      path += "/search-next/";
    } else {
      path += "/more-section-content/";
    }
    path += `?page=${page}&pathname=${window.location.pathname}`;
    loadMoreBtn.disabled = true;
    sectionObserver.remove(sectionContainer);
    window.fetch(path).then(result => {
      if (!result.ok) return;
      return result.text();
    }).then(html => {
      loadMoreBtn.disabled = false;
      if (!html) return;
      loadMoreBtn.insertAdjacentHTML("beforebegin", html);
      page++;
      const addedResults = loadMoreBtn.previousElementSibling;
      sectionObserver.init(sectionContainer);
      if (addedResults.classList.contains("js-last-page")) {
        loadMoreBtn.remove();
      }
    }).catch(() => {
      loadMoreBtn.disabled = false;
    });
  }
}