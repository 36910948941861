export default function init() {
  const buttons = document.getElementsByClassName("toggle-consent-notice");
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener("click", toggleConsentNotice);
  }
}
function toggleConsentNotice() {
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(Didomi => {
    Didomi.notice.show();
  });
}