let originalYieldFrom;
let originalYieldTo;
export default function init(scope) {
  const recipeYieldFrom = scope.getElementsByClassName("js-recipe-count__from")[0];
  const recipeYieldTo = scope.getElementsByClassName("js-recipe-count__to")[0];
  const countButtons = [...scope.getElementsByClassName("recipe-count__button")];
  const ingredientMeasurements = [...scope.getElementsByClassName("ingredient-measurement")];
  if (!countButtons.length || !ingredientMeasurements.length || !recipeYieldFrom) {
    return;
  }
  const decreaseButton = scope.getElementsByClassName("js-recipe-count__button--decrease")[0];
  const increaseButton = scope.getElementsByClassName("js-recipe-count__button--increase")[0];
  const countWarning = scope.getElementsByClassName("ingredient-yield-count__warning")[0];
  if (!recipeYieldFrom.innerText) {
    decreaseButton.style.display = "none";
    increaseButton.style.display = "none";
    return;
  }
  let newYield;
  function update(adjustment) {
    newYield = adjustYield(adjustment, recipeYieldFrom, recipeYieldTo);
    if (!newYield) {
      return;
    }
    adjustIngredientCount(newYield.from, ingredientMeasurements);
    toggleWarning(newYield.from, countWarning);
  }
  decreaseButton.addEventListener("click", () => update(-1));
  increaseButton.addEventListener("click", () => update(1));
}
function adjustYield() {
  let adjustment = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  let recipeYieldFrom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let recipeYieldTo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const yieldFrom = parseInt(recipeYieldFrom.innerHTML);
  if (!originalYieldFrom) {
    originalYieldFrom = yieldFrom;
    originalYieldTo = parseInt(recipeYieldTo?.innerHTML);
  }
  const newYieldFrom = yieldFrom + adjustment;
  if (newYieldFrom < 1) {
    return;
  }
  recipeYieldFrom.innerHTML = Math.round(newYieldFrom);
  if (originalYieldTo) {
    const newYieldTo = newYieldFrom / originalYieldFrom * originalYieldTo;
    recipeYieldTo.innerHTML = Math.round(newYieldTo);
  }
  return {
    from: newYieldFrom
  };
}
function adjustIngredientCount(yieldFrom, ingredientMeasurements) {
  const adjustmentRatio = yieldFrom / originalYieldFrom;
  ingredientMeasurements.forEach(ingredientMeasurement => {
    const originalMeasurement = parseFloat(ingredientMeasurement.dataset.originalMeasurement);
    // toPrecision in order to get 2 decimals
    let value = parseFloat((adjustmentRatio * originalMeasurement).toPrecision(3));
    // % 0.25 in order to sort out values we want to round to 1 decimal i.e 1,37 => 1,3 etc
    if (value % 0.25 !== 0) {
      value = value.toFixed(1);
    }

    // parseFloat removes trailing 0:s
    ingredientMeasurement.innerHTML = parseFloat(value);
  });
}
let warningHidden = true;
function toggleWarning(yieldFrom, element) {
  if (yieldFrom !== originalYieldFrom && warningHidden) {
    element.classList.remove("hidden");
    element.classList.add("animation");
    warningHidden = false;
  } else if (yieldFrom === originalYieldFrom) {
    element.classList.add("hidden");
    element.classList.remove("animation");
    warningHidden = true;
  }
}