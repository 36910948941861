function textShowMore(document) {
  const textShowMoreElement = document.getElementsByClassName("text-show-more");
  if (!textShowMoreElement) return;
  [...textShowMoreElement].forEach(element => {
    element.addEventListener("click", showMore);
  });
  function showMore(e) {
    e.currentTarget.classList.remove("text-show-more");
    e.currentTarget.removeEventListener("click", showMore);
  }
}
export default textShowMore;