export default function init(selector) {
  if (isPreloadSupported()) return;
  const links = document.head.getElementsByClassName(selector);
  for (let i = 0; i < links.length; ++i) {
    const link = links[i];
    link.setAttribute("onload", "");
    link.rel = "stylesheet";
  }
}
function isPreloadSupported() {
  try {
    return document.createElement("link").relList.supports("preload");
  } catch (e) {
    return false;
  }
}