import throttle from "../helpers/throttle";
export default function init(id) {
  const carousel = document.getElementById(id);
  if (!carousel) return;
  const items = carousel.getElementsByClassName("carousel-item");
  if (!items || !items.length) return;
  const navRight = carousel.getElementsByClassName("js-carousel-nav-right")[0];
  const navLeft = carousel.getElementsByClassName("js-carousel-nav-left")[0];
  const contentContainer = carousel.getElementsByClassName("js-carousel-content")[0];
  const scroller = carousel.getElementsByClassName("js-carousel-content-scroller")[0];
  const bullets = carousel.getElementsByClassName("js-bullet");
  const itemsAmount = items.length;
  let activeItemIndex = 1;
  let activeDomItem = 0;
  const domItemsAmount = items.length * 2;
  let animating = false;
  let autoRotation = true;
  let pageSize = 1;

  // Create duplicates of items to handle infinite scroll.
  for (let i = 0; i < itemsAmount; i++) {
    const cln = items[i].cloneNode(true);
    scroller.appendChild(cln);
  }
  window.addEventListener("resize", throttle(onResize));

  // Prevent inactive items in edges do be clickable.
  for (let i = 0; i <= items.length - 1; i++) {
    const el = items[i].getElementsByTagName("a")[0];
    el.addEventListener("click", event => {
      if (!items[i].classList.contains("active")) event.preventDefault();
    });
  }
  initCarousel();

  // Autoplay
  setInterval(() => {
    if (!autoRotation) return;
    slideRight();
  }, 3000);
  navLeft.addEventListener("click", slideLeft);
  navRight.addEventListener("click", slideRight);
  function slideLeft() {
    if (animating) return;
    activeItemIndex = activeItemIndex === 1 ? itemsAmount : activeItemIndex - 1;
    activeDomItem = activeDomItem === 0 ? domItemsAmount - 1 : activeDomItem - 1;
    animate();
  }
  function slideRight() {
    if (animating) return;
    activeItemIndex = activeItemIndex === itemsAmount ? 1 : activeItemIndex + 1;
    activeDomItem = activeDomItem === domItemsAmount ? domItemsAmount + 1 : activeDomItem + 1;
    animate();
  }
  function setRotation() {
    autoRotation = true;
  }
  function removeRotation() {
    autoRotation = false;
  }
  function endAnimation() {
    scroller.classList.remove("animate");
    animating = false;
    shouldReset();
    setActive();
  }
  function animate() {
    setActiveBullet();
    scroller.classList.add("animate");
    animating = true;
    const pageOffset = activeDomItem * (100 / pageSize);
    scroller.style.transform = `translateX(-${pageOffset}%)`;
    setActive();
    setTimeout(endAnimation, 300);
  }
  function scrollToIndex(index) {
    let shouldAnimate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    activeDomItem = index - 1;
    activeItemIndex = index;
    const pageOffset = (index - 1) * (100 / pageSize);
    animating = true;
    if (shouldAnimate) {
      scroller.classList.add("animate");
    }
    scroller.style.transform = `translateX(-${pageOffset}%)`;
    setActive();
    setTimeout(endAnimation, 300);
  }
  function setActive() {
    autoRotation = true;
    for (let i = 0; i <= items.length - 1; i++) {
      items[i].classList.remove("active");
      if (i === activeDomItem) {
        for (let j = 0; j <= pageSize - 1; j++) {
          items[activeDomItem + j].classList.add("active");
        }
        i = i + pageSize - 1;
      }
    }
  }

  // resets dom position to middle of all items.
  function reset() {
    bullets[0].classList.add("active");
    activeDomItem = itemsAmount;
    activeItemIndex = 1;
    const pageOffset = itemsAmount * (100 / pageSize);
    scroller.style.transform = `translateX(-${pageOffset}%)`;
  }
  function resetBack() {
    activeDomItem = itemsAmount - pageSize;
    const pageOffset = activeDomItem * (100 / pageSize);
    scroller.style.transform = `translateX(-${pageOffset}%)`;
  }

  // reset dom position to handle edges on infinite scroll.
  function shouldReset() {
    if (activeDomItem === 0) {
      reset();
    }
    if (activeDomItem + pageSize >= domItemsAmount) resetBack();
  }
  function setActiveBullet() {
    for (let i = 0; i <= bullets.length - 1; i++) {
      bullets[i].classList.remove("active");
    }
    bullets[activeItemIndex - 1].classList.add("active");
  }

  // Set initial focus for carousel
  function initCarousel() {
    if (window.CHANNEL_MOBILE) {
      pageSize = 1;
      contentContainer.classList.add("small");
    } else if (window.CHANNEL_TABLET) {
      pageSize = 2;
      contentContainer.classList.add("medium");
    } else if (window.CHANNEL_DESKTOP) {
      pageSize = 3;
      contentContainer.classList.add("large");
    }
    if (itemsAmount <= pageSize) {
      noScroll();
    } else {
      reset();
      setActive();
      scrollToIndex(Math.floor(Math.random() * Math.floor(itemsAmount)) + 1, false);
      setActiveBullet();
      carousel.addEventListener("mouseout", setRotation);
      carousel.addEventListener("mouseover", removeRotation);
    }
  }
  function noScroll() {
    autoRotation = false;
    activeDomItem = 0;
    activeItemIndex = 0;
    contentContainer.classList.add("no-scroll");
    scroller.style.transform = "translateX(0%)";
    for (let i = 0; i <= items.length - 1; i++) {
      items[i].classList.remove("active");
    }
    for (let i = 0; i <= itemsAmount - 1; i++) {
      items[i].classList.add("active");
    }
  }
  function onResize() {
    if (window.CHANNEL_MOBILE) {
      if (contentContainer.classList.contains("small")) return;
    } else if (window.CHANNEL_TABLET) {
      if (contentContainer.classList.contains("medium")) return;
    } else if (window.CHANNEL_DESKTOP) {
      if (contentContainer.classList.contains("large")) return;
    }
    contentContainer.classList.remove("small", "medium", "large", "no-scroll");
    carousel.removeEventListener("mouseout", setRotation);
    carousel.removeEventListener("mouseover", removeRotation);
    initCarousel();
  }
}