import lazyLoadAds from "./lazyLoadAds";
import resizeAd from "./resizeAd";
export default function init() {
  if (!window.Exp.bamData) return;
  window.Bau = window.Bau || [];
  const siteBody = document.getElementsByClassName("site-body")[0];
  if (!window.CHANNEL_MOBILE) {
    adResizer();
  }
  lazyLoadAds(siteBody);
  function adResizer() {
    window.Bau.push(() => {
      resizeAd(window.Bau);
    });
  }
}