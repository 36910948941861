export default function getAppChannel() {
  const {
    expNative
  } = window;
  if (!expNative) return;
  let appIdentifier;
  // saving the function to a variable, using typeof or doing an !== leads to
  // TypeError: self type check failed for Objective-C instance method javascript
  // for iOS due to its' Javascript bridge
  if (expNative.appIdentifier) {
    appIdentifier = expNative.appIdentifier();
  } else if (expNative.getAppIdentifier) {
    appIdentifier = expNative.getAppIdentifier();
  } else {
    return "hybrid app";
  }

  // includes or !== doesn't work in iOS Javascript bridge
  const deviceType = appIdentifier.indexOf("Tablet") > -1 ? "tablet" : "mobile";
  return `${deviceType} hybrid app`;
}