import scroll from "@exp/scripts/helpers/scroll";
import getWindowInnerHeight from "../helpers/getWindowInnerHeight";
import article from "./article";
import * as api from "../helpers/api";
import { pushPageView } from "@exp/scripts/integration/gtm";
const path = window.location.pathname;
export default function init(topArticle, observer) {
  if (!window.fetch || !topArticle) return;
  const container = document.getElementById("lazy-container");
  if (!container) return;
  window.Bau = window.Bau || [];
  let lazyItems;
  let spaIndex = 1;
  let currentArticleLinkUrl = path;
  getLazyArticleContentId().then(contentIds => {
    if (!contentIds || !contentIds.length) {
      return;
    }
    lazyItems = contentIds;
    topArticle.tracked = true;
    scroll.add(handleScroll(topArticle));
    container.setAttribute("data-preloaded", true);
  });
  function getNextArticle() {
    const next = lazyItems.shift();
    if (!next) return;
    if (next.contentId === undefined) return getNextArticle();
    api.fetchArticle(next.contentId).then(articleMarkup => {
      if (!articleMarkup) return getNextArticle();
      container.insertAdjacentHTML("beforeend", articleMarkup);
      const articleElement = container.lastElementChild;
      window.Bau.push(() => {
        window.Bau.preloadAds(articleElement, {
          infinityScroll: true
        });
      });
      spaIndex += 1;
      articleElement.dataset.articleIndex = spaIndex;
      articleElement.lastContinuousArticle = lazyItems.length === 0;
      article(articleElement, true, observer);
      scroll.add(handleScroll(articleElement));
    });
  }
  function getLazyArticleContentId() {
    if (!topArticle.classList.contains("branded-article")) {
      return Promise.all([api.getNativeArticle(), api.getScrollRecommendations()]).then(response => {
        const nativeContentId = response[0]?.contentId;
        const scrollRecommedationsItems = response[1]?.items;
        const scrollRecommedationsWithNative = addNativeToScrollRecommedations(nativeContentId, scrollRecommedationsItems);
        return scrollRecommedationsWithNative;
      });
    }
    return Promise.all([api.getScrollRecommendations()]).then(response => {
      const scrollRecommedationsItems = response[0]?.items || [];
      return scrollRecommedationsItems;
    });
  }
  function handleScroll(articleObj) {
    const articleElement = articleObj;
    const linkUrl = articleElement.dataset.url;
    const screenHeight = getWindowInnerHeight();
    let markedAsRead = linkUrl === path;
    let nextLoaded = false;
    return () => {
      const {
        top: articleTop,
        bottom: articleBottom
      } = articleElement.getBoundingClientRect();
      if (articleBottom < 0) return;
      const entireArticleVisible = articleTop >= 0 && articleBottom <= screenHeight;
      const articleCoversScreen = articleTop <= 0 && articleBottom >= screenHeight;
      if (entireArticleVisible || articleCoversScreen) {
        if (linkUrl !== currentArticleLinkUrl) {
          window.history.replaceState(null, null, linkUrl);
          const seoTitle = articleElement.dataset.title;
          if (seoTitle) {
            document.title = seoTitle;
          }
          currentArticleLinkUrl = linkUrl;
          window.Exp.config.articleId = articleElement.dataset.articleId;
          trackPageView(articleElement);
          if (!markedAsRead) {
            markedAsRead = true;
          }
        }
        if (!nextLoaded) {
          getNextArticle();
          nextLoaded = true;
        }
      }
    };
  }
  function trackPageView(articleElement) {
    const articleIndex = parseInt(articleElement.dataset.articleIndex || 1);
    let gtmDataElement;
    const Exp = window.Exp;
    try {
      if (articleIndex === 1) {
        gtmDataElement = document.getElementById("gtm-content");
      } else {
        gtmDataElement = articleElement.getElementsByClassName("partial-gtm-content")[0];
      }
      const {
        content,
        isPremium
      } = JSON.parse(gtmDataElement.innerText);
      pushPageView({
        content: {
          ...content,
          location: window.location.href,
          userHasAccess: isPremium ? !!(Exp.user && Exp.user.entitlements && Exp.user.entitlements.length) : true,
          articleIndex,
          trigger: "virtual page load"
        }
      });
    } catch (err) {
      // no-op
    }
    const trackingInfo = JSON.parse(articleElement.dataset.trackingInfo);
    if (!trackingInfo) return;
    window.ExpressenAnalytics = window.ExpressenAnalytics || [];
    window.ExpressenAnalytics.push(apiParam => {
      const trackingSettings = Exp && Exp.trackingSettings || {};
      apiParam.init(trackingInfo, Object.assign(trackingSettings, {
        spaIndex: parseInt(articleElement.dataset.articleIndex || 1)
      }));
      apiParam.track.pageView();
    });
  }
  function addNativeToScrollRecommedations(nativeContentId, scrollRecommedationsItems) {
    let articleList = [];
    if (nativeContentId) {
      articleList.push({
        contentId: nativeContentId
      });
    }
    if (scrollRecommedationsItems) {
      articleList = [...articleList, ...scrollRecommedationsItems];
    }
    return articleList;
  }
}