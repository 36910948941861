export default function init(scope) {
  const checkboxes = [...scope.getElementsByClassName("recipe-checkbox")];
  if (!checkboxes.length) {
    return;
  }
  checkboxes.forEach(checkbox => {
    checkbox.addEventListener("click", _ref => {
      let {
        target
      } = _ref;
      const {
        name,
        checked
      } = target;
      if (!checked) {
        scope.getElementsByClassName(`${name}`)[0].classList.remove("checked");
      } else {
        scope.getElementsByClassName(`${name}`)[0].classList.add("checked");
      }
    });
  });
}