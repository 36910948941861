import scrollHelpers from "@exp/scripts/helpers/scroll";
import topMenu from "@exp/scripts/plugins/top-menu";
export default function init() {
  const element = document.getElementsByClassName("site-header")[0];
  if (!element) return;
  const [row1, row2] = element.children;
  const row2Height = row2.getBoundingClientRect().height;
  const documentBody = document.body;
  const menuScrollBackThreshold = 20;
  let isStuck = false;
  let isExpanded = false;
  let animate;
  topMenu(element, false);
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  row1.addEventListener("click", trackInteraction);
  scrollHelpers.add(evaluateSticky, true);
  scrollHelpers.add(evaluateExpanded);
  function evaluateSticky(_ref) {
    let {
      scrollY,
      lastScrollY
    } = _ref;
    let forceEvaluation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (!forceEvaluation && scrollY === lastScrollY) return;
    const {
      bottom
    } = element.getBoundingClientRect();
    const belowHeader = bottom <= row2Height;
    if (belowHeader && (forceEvaluation || scrollY > lastScrollY)) {
      stick();
    } else if (!belowHeader) {
      unstick();
    }
  }
  function stick() {
    documentBody.classList.add("site-header-stuck");
    isStuck = true;
    animate = setTimeout(() => {
      documentBody.classList.add("site-header-animated");
      animate = undefined;
    }, 0);
  }
  function unstick() {
    if (animate) animate = clearTimeout(animate);
    documentBody.classList.remove("site-header-stuck", "site-header-animated");
    isStuck = false;
  }
  function evaluateExpanded(_ref2) {
    let {
      scrollY,
      lastScrollY
    } = _ref2;
    if (!isStuck) return;
    if (isExpanded && scrollY > lastScrollY) {
      documentBody.classList.remove("site-header-expanded");
      isExpanded = false;
    } else if (!isExpanded && lastScrollY - scrollY >= menuScrollBackThreshold) {
      documentBody.classList.add("site-header-expanded");
      isExpanded = true;
    }
  }
  function trackInteraction(event) {
    const link = event.target.closest("a[data-label]");
    if (!link) return;
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("navigation", "click", link.dataset.label);
    });
  }
}