export default function bindShowMoreButton(scope) {
  const searchContainer = scope.getElementsByClassName("js-search-page")[0];
  if (!searchContainer) return;
  let showMoreButton = searchContainer.getElementsByClassName("js-search-page-show-more")[0];
  if (!showMoreButton) return;
  const resultList = searchContainer.getElementsByClassName("js-search-result-list")[0];
  const totalCountElement = searchContainer.getElementsByClassName("search-page__hits");
  const hitCounters = searchContainer.getElementsByClassName("search-page__visible-hits");
  let page = 2;
  const totalCount = getTotalCount();
  const computedTotalCount = totalCount;
  showMoreButton.addEventListener("click", onShowMore);
  function onShowMore() {
    showMoreButton.disabled = true;
    const query = decodeURI(window.location.search);
    const path = window.Exp.config.hasTopDomain ? `/search-next/${query}&page=${page}` : `${window.Exp.config.path}/search-next/${query}&page=${page}`;
    window.fetch(path, {
      credentials: "same-origin"
    }).then(result => {
      if (result.status !== 200) return;
      return result.text();
    }).then(markup => {
      if (!markup || markup.length <= 1) {
        return;
      }
      if (resultList) {
        resultList.insertAdjacentHTML("beforeend", markup);
      }
      updateStatus();
      page++;
    }).catch(onError);
  }
  function updateStatus() {
    const numberOfHits = searchContainer.getElementsByClassName("teaser").length;
    if (numberOfHits >= computedTotalCount) {
      showMoreButton.removeEventListener("click", onShowMore);
      showMoreButton.classList.add("hidden");
      showMoreButton = null;
    } else {
      showMoreButton.disabled = false;
    }

    // Update all the hit-counters
    for (let i = hitCounters.length - 1; i >= 0; --i) {
      hitCounters[i].textContent = numberOfHits;
    }
  }
  function onError() {
    if (showMoreButton) {
      showMoreButton.disabled = false;
    }
  }
  function getTotalCount() {
    if (!totalCountElement) return 0;
    const total = parseInt(totalCountElement[0].textContent);
    return isNaN(total) ? 0 : total;
  }
}