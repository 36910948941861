const promptsByVendor = {};
let template;
let nPrompts = 0;
let consentChangedListener;
let optInTrigger;
export default function consentPrompt(_ref) {
  let {
    target,
    vendorId,
    className,
    callback = () => {}
  } = _ref;
  if (!target) return;
  if (!window.Didomi.getVendorById(vendorId)) return;
  template = template || document.getElementById("consent-prompt-template");
  if (!template) return;
  const fragment = document.importNode(template.content, true);
  let prompt = fragment.querySelector(".consent-prompt");
  const description = getDescription(vendorId);
  prompt.firstElementChild.insertAdjacentHTML("afterend", description);
  const sourceLink = fragment.querySelector(".consent-prompt__source a");
  const href = target.href;
  if (href) {
    sourceLink.href = href;
    sourceLink.innerHTML = wordBreakLink(href);
  } else {
    const source = sourceLink.parentElement;
    source.parentElement.removeChild(source);
  }
  prompt.id = `consent-prompt-${nPrompts++}`;
  if (className) {
    prompt.classList.add(className);
  }
  target.parentElement.insertBefore(prompt, target);
  prompt = document.getElementById(prompt.id); // Tallahassee workaround
  promptsByVendor[vendorId] = promptsByVendor[vendorId] || [];
  promptsByVendor[vendorId].push({
    prompt,
    callback
  });
  const button = prompt.getElementsByTagName("button")[0];
  button.addEventListener("click", () => {
    optInTrigger = vendorId;
    window.Didomi.notice.show();
  });
  consentChangedListener = consentChangedListener || window.Didomi.on("consent.changed", togglePrompts);
}
function togglePrompts() {
  for (const vendorId in promptsByVendor) {
    if (!window.Didomi.getUserConsentStatusForVendor(vendorId)) continue;
    promptsByVendor[vendorId].forEach(_ref2 => {
      let {
        prompt,
        callback
      } = _ref2;
      prompt.classList.add("hidden");
      callback();
    });
    promptsByVendor[vendorId].length = 0;
  }
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  window.ExpressenAnalytics.push(analytics => {
    analytics.track.customActive("consent", "opt-in", `consent prompt:${optInTrigger}`);
  });
}
function wordBreakLink(href) {
  return href.replace(/(\W+)(?=.)/g, "<wbr>$1");
}
function getDescription(vendorId) {
  const vendor = window.Didomi.getVendorById(vendorId);
  if (!vendor) return;
  const {
    name,
    purposeIds
  } = vendor;
  const hasDeniedVendor = !window.Didomi.getUserStatus().vendors.consent.enabled.includes(vendorId);
  let hasAdRelatedPurpose = false;
  const purposes = purposeIds.map(pid => window.Didomi.getPurposeById(pid)).filter(p => {
    if (window.Didomi.getUserConsentStatusForPurpose(p.id)) return false;
    hasAdRelatedPurpose = hasAdRelatedPurpose || p.namespace !== "custom";
    return p.namespace === "custom";
  });
  if (hasAdRelatedPurpose) {
    purposes.push({
      name: {
        sv: "Annonsrelaterade ändamål"
      }
    });
  }
  const hasDeniedPurposes = !!purposes.length;
  const showNumbers = hasDeniedPurposes && hasDeniedVendor;
  const purposeTemplate = hasDeniedPurposes ? `
    <li>
      Gå till "Fler inställningar" och godkänn:
      <ul>
        ${purposes.map(p => `
          <li>${typeof p.name === "string" ? p.name : p.name.sv}</li>
        `).join("")}
      </ul>
    </li>
  ` : "";
  const vendorTemplate = hasDeniedVendor ? `
    <li>
      Gå till "Visa våra partners" och godkänn:
      <ul>
        <li>${name}</li>
      </ul>
    </li>
  ` : "";
  return `
    <ol ${showNumbers ? "" : "class='consent-prompt__unnumbered-list'"}>
      ${purposeTemplate}
      ${vendorTemplate}
    </ol>
  `;
}