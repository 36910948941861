function handleKortio(message) {
  if (message.data.iframeIsReady) {
    const kortioWidgets = document.getElementsByClassName("kortio-widget");
    for (let i = 0; i < kortioWidgets.length; i++) {
      const widget = kortioWidgets[i];
      const id = `kortio-widget-id-${i}`;
      widget.id = id;
      widget.contentWindow.postMessage({
        iframeId: id
      }, "*");
    }
  } else if (message.data.iframeId) {
    const widget = document.getElementById(message.data.iframeId);
    if (widget) {
      widget.style.height = `${message.data.height}px`;
    }
  }
}
export default {
  handleKortio
};