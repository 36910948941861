const useObserver = ("IntersectionObserver" in window);
import handleOutsiders from "./handleOutsiders";
const observers = new Map();
function init(section) {
  if (!section || !useObserver) return;
  const viewportHeight = window.innerHeight;
  observers.set("section", getSectionObserver(section));
  function getSectionObserver() {
    const options = {
      rootMargin: `${section.offsetHeight}px 0px 0px 0px`,
      threshold: [...Array(101).keys()].map(value => value * 0.01)
    };
    if (window.CHANNEL_DESKTOP) {
      handleOutsiders.init(section);
    }
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const percentageScrolled = Math.round(-entry.boundingClientRect.top / (entry.boundingClientRect.height - viewportHeight) * 100);
        if (window.CHANNEL_DESKTOP) {
          handleOutsiders.update(section, percentageScrolled, 90);
        }
      });
    }, options);
    observer.observe(section);
    return observer;
  }
}
function remove(section) {
  if (observers.has("section")) {
    const observer = observers.get("section");
    observer.unobserve(section);
    observers.delete("section");
  }
}
export default {
  init,
  remove
};