import throttle from "@exp/scripts/helpers/throttle";
import passiveIfPossible from "@exp/scripts/helpers/passive-if-possible";
let listeningToScroll = false;
const scrollListeners = [];
let scrollX = window.pageXOffset;
let scrollY = window.pageYOffset;
let lastScrollX = scrollX;
let lastScrollY = scrollY;
export default {
  add: addScrollListener,
  remove: removeScrollListener,
  isContainerInView,
  getScrollX,
  getScrollY,
  toggleLock
};
function scroll() {
  scrollX = window.pageXOffset;
  scrollY = window.pageYOffset;
  const listeners = scrollListeners.slice();
  for (let i = 0; i < listeners.length; ++i) {
    listeners[i]({
      scrollX,
      lastScrollX,
      scrollY,
      lastScrollY
    });
  }
  lastScrollX = scrollX;
  lastScrollY = scrollY;
}
function scrollListenerExists(fn) {
  return scrollListeners.indexOf(fn) > -1;
}
function removeScrollListener(fn) {
  const index = scrollListeners.indexOf(fn);
  if (index === -1) return;
  scrollListeners.splice(index, 1);
}
function addScrollListener(fn, immediate) {
  if (scrollListenerExists(fn)) return;
  scrollListeners.push(fn);
  if (immediate) {
    fn({
      scrollX,
      lastScrollX,
      scrollY,
      lastScrollY
    }, true);
  }
  if (listeningToScroll) return;
  listeningToScroll = true;
  window.addEventListener("scroll", throttle(scroll, 100), passiveIfPossible());
}
function isContainerInView(container) {
  if (container) {
    const {
      height,
      top
    } = container.getBoundingClientRect();
    const containerMiddle = top + height / 2;
    if (containerMiddle > 0 && containerMiddle < window.innerHeight) {
      return true;
    }
  }
  return false;
}
function getScrollX() {
  return scrollX;
}
function getScrollY() {
  return scrollY;
}
function toggleLock(force) {
  const target = document.documentElement.classList;
  const token = "no-scroll";
  if (typeof force === "undefined") target.toggle(token);else target[force ? "add" : "remove"](token);
}