import { content, environment } from "@exp/scripts/integration/gtm";
window.Exp = window.Exp || {};
window.ExpressenAnalytics = window.ExpressenAnalytics || [];
const coreWebVitalsNames = ["CLS", "LCP", "INP"];
const scoreByRating = {
  poor: 1,
  "needs-improvement": 2,
  good: 3
};
let metrics;
export default function init() {
  if (!window.webVitals || !window.webVitals.onCLS) {
    const script = document.getElementById("web-vitals-script");
    return script && script.addEventListener("load", init);
  }
  window.webVitals.onCLS(register);
  window.webVitals.onFCP(register);
  window.webVitals.onFID(register);
  window.webVitals.onINP(register);
  window.webVitals.onLCP(register);
  window.webVitals.onTTFB(register);

  // Report all available metrics whenever the page is backgrounded or unloaded.
  window.addEventListener("visibilitychange", track);

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  window.addEventListener("pagehide", track);
}
function register(event) {
  const {
    name,
    value,
    rating
  } = event;
  metrics = metrics || {};
  metrics[name] = {
    name,
    value,
    rating
  };
}
function track(event) {
  if (!metrics) return;
  if (event.type === "visibilitychange" && document.visibilityState !== "hidden") return;
  if (window.CHANNEL_MOBILE && window.Exp.config.rumReporterUrl) {
    navigator.sendBeacon(window.Exp.config.rumReporterUrl, JSON.stringify({
      metrics: Object.values(metrics),
      "device.type": window.Exp.channel,
      "environment.platform.name": environment.platform.name,
      "content.type": content.type,
      "environment.experiments": getExperiments()
    }));
  }
  if (window.Exp.userGroup === 100) {
    let lowestScore, lowestRating;
    for (const name in metrics) {
      if (!Object.prototype.hasOwnProperty.call(metrics, name)) continue;
      const {
        value,
        rating
      } = metrics[name];
      if (coreWebVitalsNames.includes(name)) {
        const score = scoreByRating[rating];
        lowestScore = lowestScore ? Math.min(score, lowestScore) : score;
        lowestRating = lowestScore === score ? rating : lowestRating;
      }

      // value must be integer in GA
      const eventValue = Math.round(name === "CLS" ? value * 1000 : value);
      window.ExpressenAnalytics.push(api => {
        api.track.customPassive("Web Vitals", name, rating, {
          eventValue
        });
      });
    }
    if (typeof lowestRating !== "undefined") {
      window.ExpressenAnalytics.push(api => {
        api.track.customPassive("Web Vitals", "Page View Rating", lowestRating);
      });
    }
  }
  metrics = null;
}
function getExperiments() {
  let experiments;
  for (const experiment in window.Exp.experiments) {
    experiments = experiments || [];
    const variant = window.Exp.experiments[experiment];
    experiments.push(variant === 1 ? experiment : `${experiment}:${variant}`);
  }
  return experiments;
}