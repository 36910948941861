let doesHaveCMP;
const purposes = {
  analytics: "analysutv-BcCq4VEq",
  marketing: "marknadsfo-G6hNqgqm",
  functional: "funktionel-t2meYCeq"
};
export function checkConsent(purpose) {
  let vendor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "c:bonniernews";
  let callback = arguments.length > 2 ? arguments[2] : undefined;
  if (!hasCMP()) return callback(true);
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(Didomi => {
    return callback(Didomi.getUserConsentStatus(purposes[purpose], vendor));
  });
}
export function checkConsentForPurpose(purpose, callback) {
  if (!hasCMP()) return callback(true);
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(Didomi => {
    return callback(Didomi.getUserConsentStatusForPurpose(purposes[purpose]));
  });
}
export function checkConsentForPurposeSync(purpose) {
  if (!hasCMP()) return true;
  if (!window.Didomi) return undefined;
  return window.Didomi.getUserConsentStatusForPurpose(purposes[purpose]);
}
export function checkConsentSync(purpose) {
  let vendor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "c:bonniernews";
  if (!hasCMP()) return true;
  if (!window.Didomi) return undefined;
  return window.Didomi.getUserConsentStatus(purposes[purpose], vendor);
}
export function checkConsentForVendor(vendor) {
  if (!hasCMP()) return true;
  if (!window.Didomi) return undefined;
  return window.Didomi.getUserConsentStatusForVendor(vendor);
}
export function getPurpose(name, callback) {
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(Didomi => {
    return callback(Didomi.getPurposeById(purposes[name]));
  });
}
export function getPurposeSync(name) {
  if (!window.Didomi) return undefined;
  return window.Didomi.getPurposeById(purposes[name]);
}
function hasCMP() {
  if (typeof doesHaveCMP === "undefined") {
    doesHaveCMP = !!document.getElementById("cmp-script");
  }
  return doesHaveCMP;
}