const useObserver = ("IntersectionObserver" in window);
import articleObserver from "./articleObserver";
import sectionObserver from "./sectionObserver";
export default function pageObserver() {
  let pageType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "article";
  if (!useObserver) return;
  return new IntersectionObserver(viewPortUpdate);
  function viewPortUpdate(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (pageType === "article") {
          articleObserver.init(entry.target);
        } else if (pageType === "section") {
          sectionObserver.init(entry.target);
        }
      } else {
        if (pageType === "article") {
          articleObserver.remove(entry.target);
        } else if (pageType === "section") {
          sectionObserver.remove(entry.target);
        }
      }
    });
  }
}