export default function init(scope) {
  const elements = scope.getElementsByClassName("js-factbox");
  if (!elements.length) return;
  for (let i = 0; i < elements.length; i++) {
    factbox(elements[i]);
  }
}
function factbox(element) {
  const expandable = element.scrollHeight - element.offsetHeight > 100;
  const toggleButton = element.getElementsByClassName("button")[0];
  if (!expandable || !toggleButton) return open();
  toggleButton.addEventListener("click", open);
  function open() {
    element.classList.toggle("factbox--open");
  }
}