let shouldUpdateAd, hasUpdatedAd, adContainer;
function init(container) {
  let minHeight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4000;
  if (!container || window.innerWidth < 1600) return;
  trim(container);
  adContainer = [...container.getElementsByClassName("outsider-ads__ad-slot")].pop();
  if (!adContainer) return;
  const {
    height
  } = container.getBoundingClientRect();
  shouldUpdateAd = height >= minHeight;
  if (!shouldUpdateAd) return;
  hasUpdatedAd = false;
}
function update(container, percentageScrolled) {
  let minDepth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 70;
  if (!container || window.innerWidth < 1600) return;
  if (shouldUpdateAd && percentageScrolled >= minDepth && percentageScrolled <= minDepth + 15 && !hasUpdatedAd) {
    hasUpdatedAd = true;
    replaceAd(adContainer);
  }
}
function replaceAd(adSlot) {
  if (!window.Bau || !adSlot) return;
  window.Bau.push(() => {
    window.Bau.displayAds(adSlot);
  });
}
function trim(container) {
  const outsiderContainer = container && container.getElementsByClassName("outsider-ads")[0];
  if (!outsiderContainer) return;
  const offsetTop = outsiderContainer.offsetTop;
  const bodySiteHeight = container.offsetHeight - offsetTop;
  if (bodySiteHeight > 2000) return;
  const outsiderTwo = container.getElementsByClassName("outsider-2")[0];
  const outsiderThree = container.getElementsByClassName("outsider-3")[0];
  if (bodySiteHeight < 1400) {
    removeOutsider(outsiderTwo);
    removeOutsider(outsiderThree);
    if (offsetTop > container.offsetHeight) {
      outsiderContainer.style.top = `${bodySiteHeight / 2}px`;
    }
    return;
  }
  if (bodySiteHeight < 2500) {
    removeOutsider(outsiderThree);
    if (offsetTop > container.offsetHeight) {
      outsiderContainer.style.top = `${bodySiteHeight / 2}px`;
    }
  }
  function removeOutsider(element) {
    if (!element || !element.dataset || !element.dataset.slotName) return;
    element.removeAttribute("data-slot-name");
    element.classList.remove("outsider-ads__ad-slot");
  }
}
export default {
  init,
  update,
  trim
};